<template>
    <div class="login-container">
      <div class="login-content">
        <img
              class="image_1"
              referrerpolicy="no-referrer"
              @click="close()"
              src="../img/close.png"
            />
        
        <div class="form-group">
        <input
            type="text"
            id="phone"
            v-model="phone"
            placeholder="请输入手机号"
            class="input-field"
        />
        </div>
        
        <div class="form-group">
        <div class="verification-wrapper">
            <input
            type="text"
            id="verificationCode"
            v-model="verificationCode"
            placeholder="请输入验证码"
            class="verification-input-field"
            />
            <button
            :disabled="isButtonDisabled"
            @click="getVerificationCode"
            class="verification-button"
            >
            {{ buttonText }}
            </button>
        </div>
        </div>
        <div v-on:click="selectedradio()">
        <input type="radio" name="option" value="1" v-model="selectedOption" />
        <span class="text-group_1">我已详细阅读并同意《心探科技隐私政策》</span></div>
        <button class="login-button" @click="login">登录</button>
        <p v-if="phoneError" class="error-message">{{ phoneError }}</p> <!-- 显示错误信息 -->
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import { getCooperateCode } from '@/utils/cooperate';

export default {
    name: 'UserLogin',
    data() {
        return {
            phone: '',
            verificationCode: '',
            isButtonDisabled: false,
            buttonText: '获取验证码',
            countdown: 60,
            phoneError: '', // 用于存储手机号错误提示信息
            selectedOption:null,
            errorMessage:''
        };
    },
    methods: {
      selectedradio(){
        this.selectedOption=1;
      },
        validatePhoneNumber(phone) {
            // 正则表达式，验证手机号格式（中国大陆手机号）
            const phoneRegex = /^1[3-9]\d{9}$/;
            return phoneRegex.test(phone);
        },
        getVerificationCode() {
            if (!this.validatePhoneNumber(this.phone)) {
                this.phoneError = '请输入有效的手机号';
                this.shakeErrorMessage();
                return;
            }
            
            // 清除错误信息
            this.phoneError = '';

            if (this.isButtonDisabled) return;
            
            this.isButtonDisabled = true;
            this.buttonText = `${this.countdown}秒后重发`;
            
            const interval = setInterval(() => {
                this.countdown--;
                this.buttonText = `${this.countdown}秒后重发`;

                if (this.countdown === 0) {
                clearInterval(interval);
                this.isButtonDisabled = false;
                this.buttonText = '获取验证码';
                this.countdown = 60;
                }
            }, 1000);
            
            // 这里可以添加获取验证码的实际请求逻辑
            console.log('发送验证码');
            this.SendPhoneCode();
        },
        shakeErrorMessage() {
        const errorMessage = document.querySelector('.error-message');
        if (errorMessage) {
            errorMessage.classList.add('shake');
            setTimeout(() => {
            errorMessage.classList.remove('shake');
            }, 1000); // 震动动画持续时间
        }
        },
        login() {
          if (!this.validatePhoneNumber(this.phone)) {
                this.phoneError = '请输入有效的手机号';
                this.shakeErrorMessage();
                return;
            }
            if (!this.verificationCode) {
                  this.phoneError = '请输入验证码';
                  this.shakeErrorMessage();
                  return;
              }
            if(this.selectedOption){
   
              try {
                const cooperateCode = getCooperateCode();
                axios.post('https://gdmapi.gogo365.com/Login/SendCheckNum', new URLSearchParams({
                    phoneNum: this.phone,
                    checkNum: this.verificationCode,
                    cooperateCode: cooperateCode
                }),{
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                    }
                  }).then(response => {
                    const token = response.data.Data.Jwt;
                    const nickName = response.data.Data.NickName;
                  if(token){
                    localStorage.setItem('token',token)
                    localStorage.setItem('nickName',nickName)
                    localStorage.setItem('phone',this.phone)
                    // 登录成功，发出自定义事件
                    this.$emit('login-success');
                  }
                })
            } catch (error) {
                console.error('请求出错:', error);
            }
          }else{
            this.phoneError = '请阅读勾选隐私政策';
            this.shakeErrorMessage();
          }
            
        },
        close(){
          this.$emit('login-success');
        },
        SendPhoneCode(){
            try {
              const cooperateCode = getCooperateCode();
              axios.post('https://gdmapi.gogo365.com/Login/GetPhoneCode', new URLSearchParams({
                  phoneNum: this.phone,
                  cooperateCode: cooperateCode
              })); 
            } catch (error) {
                alert('请求出错')
                console.error('请求出错:', error);
            }
        }
    },
};
</script>
  
<style scoped>
  .login-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('~@/img/login.png'); /* 设置背景图路径 */
    background-size: cover; /* 背景图等比例铺满 */
    background-position: center; /* 背景居中 */
    background-repeat: no-repeat; /* 防止背景图重复 */
    border-radius: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 80%; /* 设置最大宽度 */
    width: 400px; /* 设定固定宽度 */
    z-index: 1000;
  }

  .login-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .input-field,
  .verification-input-field {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .verification-wrapper {
    display: flex;
    align-items: center;
  }
  
  .verification-input-field {
    flex: 1; /* 使输入框占据剩余空间 */
    margin-right: 10px; /* 增加输入框和按钮之间的间距 */
  }
  
  .verification-button {
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    background-color: #FF5F5F;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .verification-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #FF5F5F;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .shake {
    animation: shake 0.5s;
  }
  
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  .text-group_1 {
  overflow-wrap: break-word;
  color: rgba(135, 110, 193, 1);
  font-size: 3vw;
  letter-spacing: 1.2000000476837158px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
}
.image_1 {
  margin-left: 90%;
}
</style>