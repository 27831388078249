<template>
    <div class="container" id="container">
      <header>
        <span class="text_1">支付</span>
      </header>
      <div class="text-wrapper_4 flex-row">
        <span class="text_2">已选择充值套餐：</span>
      </div>
      <main>
        <div class="camp">
          <h2>{{ selectedItem.name }}</h2>
          <p>{{ selectedItem.description }}</p>
        </div>
      </main>
        <div class="box_1 flex-col">
          <div class="group_5 flex-row" v-on:click="selectedzfb">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="https://lanhu-oss.lanhuapp.com/SketchPng4e65b273c959f3ca52e1a5ee3e68928f049ab52116b9140646074cd2480356fb"
            />
            <span class="text_3">支付宝支付</span>
            <input type="radio" :value="alipay" v-model="selectedOption" class="radio-btn">
          </div>
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="https://lanhu-oss.lanhuapp.com/SketchPngded2467a0cec0afdbddc1ba5829450ac044d298c24ee1e589f067784a5caa8bd"
          />
          <div class="group_6 flex-row"  v-on:click="selectedwx">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="https://lanhu-oss.lanhuapp.com/SketchPng02b7cecc49596a919a6c80195c7ba1f03530ee6aa047a44eab9c4173b152a403"
            />
            <span class="text_4">微信支付</span>
            <input type="radio" :value="wechat" v-model="selectedOption" class="radio-btn">
          </div>
        </div>
        <div class="box_9" v-show="true">
            <span class="text_16">¥</span> <span class="text_17">{{selectedItem.price}}</span>
            <span class="text_18">实付金额</span>
            <div class="text-wrapper_7" @click="goPay()">
              <span class="text_19">立即支付</span>
            </div>
          </div>
          <div class="text-wrapper_2">
          <span class="text_5">付款账号手机：</span>
          <span class="text_6">{{ phone }}</span>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MfDetailInfo',
    mounted() {
      this.selectedItem = JSON.parse(this.$route.query.iteminfo);  // 解析传递过来的对象
      localStorage.setItem('textgroup',this.selectedItem.name)
    },
    data() {
      return {
        selectedItem:{},
        selectedOption:'',
        isshowapy:false,
        wechat:'wechat',
        alipay:'alipay',
        phone:localStorage.getItem('phone')
      };
    },
    methods: {
      selectedwx(){
        this.selectedOption=this.wechat
      },
      selectedzfb(){
        this.selectedOption=this.alipay
    },
      goPay(){
        if (this.selectedOption) {
          if(this.selectedOption==this.wechat){
            //微信
            this.payWithWeChat();
          }else{
            this.payWithAlipay();
          }
        } else {
          alert('请选择支付方式');
        }
      },
      payWithWeChat() {
        if (this.selectedItem) {
            const cooperateCode = localStorage.getItem('cooperateCode') || '';
            // 在这里将选中的项目传递到支付逻辑中
            const redirectUri = encodeURIComponent('https://wxjspay.gogo365.com/callback?selectedItem=' + this.selectedItem.id + '&cooperateCode=' + cooperateCode);
            const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx164143eb55198c16&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base#wechat_redirect`;
            window.location.href = authUrl;
        } else {
            alert('请选择支付选项');
        }
      },
      payWithAlipay() {
        if(this.selectedItem){
            const cooperateCode = localStorage.getItem('cooperateCode') || '';
            // 调用后端接口生成支付订单并返回支付页面
            this.$http.post('/AlipayH5/CreateOrder', {
              SelectedItem: this.selectedItem.id,
              cooperateCode: cooperateCode
            }).then(response => {
              console.log(response);
              if (response.data) {
                // 处理支付表单
                document.getElementById('container').innerHTML = response.data;
                // 隐藏表单
                document.getElementById('alipaysubmit').style.display = 'none';
                document.forms['0'].submit();
              } else {
                console.error('支付失败或未能获取到支付页面');
              }
            })
            .catch(error => {
              console.error(error);
            });
        }else {
            alert('请选择支付选项');
        }
      },
    }
  };
  </script>
  
  <style scoped>
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  
/* 让最外层容器有背景图并高度铺满 */
.container {
  background-image: url('~@/img/bjt.png'); /* 设置背景图路径 */
  background-size: cover; /* 背景图等比例铺满 */
  background-position: center; /* 背景居中 */
  background-repeat: no-repeat; /* 防止重复 */
  min-height: 100vh; /* 高度铺满整个视口 */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* 防止整个容器横向溢出 */
  padding: 2vw; /* 使用相对单位 */
}
.text-wrapper_4 {
  margin: 3vw 48vw 0 0;
}
.text_2 {
  overflow-wrap: break-word;
  color: rgba(196, 82, 14, 1);
  font-size: 5vw;
  letter-spacing: 1.399999976158142px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 10vw;
}
  
  header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  header h1 {
    font-size: 2em;
    margin: 0;
  }
  
  header p {
    font-size: 1.2em;
    color: #555;
  }
  
  main {
    margin-bottom: 20px;
  }
  
  .camp {
    border: 1px solid #ddd;
    border-radius: 4vw;
    padding: 3vw;
    background-color: #fafafa;
  }
  
  .camp h2 {
    margin-top: 0;
    margin-bottom: 1vw;
    color: #333;
    text-align: left;
  }
  .camp p {
    margin-top: 0;
    text-align: left;
    color: rgba(163, 146, 202, 1);
  }
  
  footer {
    text-align: center;
    font-size: 1.1em;
  }
  
  footer a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  footer a:hover {
    text-decoration: underline;
  }

  .pay-button {
  background-color: #4CAF50; /* 默认绿色背景 */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.wechat {
  background-color: #09BB07; /* 微信支付按钮绿色 */
}

.alipay {
  background-color: #007AFF; /* 支付宝支付按钮蓝色 */
}

.box_1 {
  background-color: #ffffff;
  border-radius: 4vw;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  padding: 2vw 0;
  margin: 4vw 0;
}

.group_5, .group_6 {
  height: 16vw;
  display: flex;
  align-items: center;
  padding: 0 6vw;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.group_5:hover, .group_6:hover {
  background-color: #f8f8f8;
}

.image_2, .image_4 {
  width: 8vw;
  height: 8vw;
  object-fit: contain;
}

.text_3, .text_4 {
  color: #333333;
  font-size: 4vw;
  font-weight: 500;
  margin-left: 4vw;
  flex: 1;
}

.image_3 {
  width: 88%;
  height: 1px;
  background-color: #f0f0f0;
  margin: 0 auto;
}

.radio-btn {
  appearance: none;
  -webkit-appearance: none;
  width: 5vw;
  height: 5vw;
  border: 2px solid #ddd;
  border-radius: 50%;
  margin: 0;
  position: relative;
  transition: all 0.2s ease;
  cursor: pointer;
}

.radio-btn:checked {
  border-color: #FF5F5F;
  background-color: #FF5F5F;
}

.radio-btn:checked::after {
  content: '';
  position: absolute;
  width: 2vw;
  height: 2vw;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.group_5:has(.radio-btn:checked) .text_3,
.group_6:has(.radio-btn:checked) .text_4 {
  color: #FF5F5F;
}

.box_9 {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平方向居中 */
  position: fixed;
  bottom: 10vw; /* 固定在屏幕底部 */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* 可选：添加顶部阴影 */
  text-align: center; /* 水平居中内容 */
  background-color: rgba(255, 255, 255, 1);
  border-radius: 9vw;
  width: 89vw;
  align-self: center;
}
.text_16 {
  overflow-wrap: break-word;
  color: rgba(255, 83, 83, 1);
  font-size: 6vw; /* 使用相对单位 */
  letter-spacing: 0.5vw;
  font-family: SourceHanSansCN-Heavy;
  font-weight: 900;
  text-align: left;
  white-space: nowrap;
  margin: 1vw 0 0 2vw;
}

.text_17 {
  overflow-wrap: break-word;
  color: rgba(255, 83, 83, 1);
  font-size: 10vw !important; /* 使用相对单位 */
  letter-spacing: 0.5vw;
  font-family: SourceHanSansCN-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
}
.text_18 {
  overflow-wrap: break-word;
  color: rgba(255, 92, 92, 1);
  font-size: 5vw;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 2vw;
  margin: 4vw 0 0 0px;
}
.text_19{
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 5vw;
  letter-spacing: -0.8399999737739563px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  white-space: nowrap;
  line-height: 61px;
}
.text-wrapper_7 {
  flex: 1; /* 占 3 分之 1 */
  line-height: 19vw; /* 与高度相同 */
  text-align: center; /* 水平居中 */
  background-color: #FF5F5F;
  background-size: 100% 100%;
  margin-left: 13vw;
  height: 19vw;
  border-radius: 0 9vw 9vw 0;
}

.text_6 {
  overflow-wrap: break-word;
  color: rgba(45, 145, 206, 1);
  font-size: 4vw;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  line-height: 50px;
}
.text-wrapper_2{
  text-align: left;
}
  </style>